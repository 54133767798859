import React from "react";

const Solutions = () => {
  return <div>
     {/* Service Start */}
  <div className="container-xxl py-5">
    <div className="container py-5 px-lg-5">
      <div className="wow fadeInUp" data-wow-delay="0.1s">
        <p className="section-title text-secondary justify-content-center">
          <span />
          Our Services
          <span />
        </p>
        <h1 className="text-center mb-5">What Solutions We Provide</h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-search fa-2x" />
            </div>
            <h5 className="mb-3">SEO Optimization</h5>
            <p className="m-0">
              Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
              sed diam stet diam sed stet lorem.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-laptop-code fa-2x" />
            </div>
            <h5 className="mb-3">Web Design</h5>
            <p className="m-0">
              Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
              sed diam stet diam sed stet lorem.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fab fa-facebook-f fa-2x" />
            </div>
            <h5 className="mb-3">Social Media Marketing</h5>
            <p className="m-0">
              Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
              sed diam stet diam sed stet lorem.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-mail-bulk fa-2x" />
            </div>
            <h5 className="mb-3">Email Marketing</h5>
            <p className="m-0">
              Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
              sed diam stet diam sed stet lorem.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-thumbs-up fa-2x" />
            </div>
            <h5 className="mb-3">PPC Advertising</h5>
            <p className="m-0">
              Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
              sed diam stet diam sed stet lorem.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fab fa-android fa-2x" />
            </div>
            <h5 className="mb-3">App Development</h5>
            <p className="m-0">
              Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
              sed diam stet diam sed stet lorem.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service End */}
  </div>;
};

export default Solutions;
